<script>
import { ref } from "vue";
import { chunk } from "lodash";
import PostApi from "@/http/post";
export default {
    name: "Posts",

    async setup() {
        const posts = ref([]);

        posts.value = await PostApi.getAllResources();

        const getDate = (date) => {
            const options = { dateStyle: "medium" };
            const formatter = new Intl.DateTimeFormat("en", options);

            return formatter.format(new Date(date));
        };

        const postsChunks = chunk(posts.value, 3)

        const getTarget = (isLink) => {
            return isLink ? '_blank' : '_self'
        }

        const getLinkHref = (post) => {
            if (post.is_link) return post.body
            return `/blog/${post.slug}`
        }

        return {
            getDate,
            getLinkHref,
            getTarget,
            posts,
            postsChunks
        };
    },
};
</script>
<template>
    <section class="page-title page-title-1 image-bg overlay">
        <div class="background-image-holder">
            <img
                alt="Background Image"
                class="background-image"
                src="@/index/assets/img/blog-bg.jpg"
            />
        </div>
        <div class="container">
            <div class="row">
                <div class="col-sm-12 text-center">
                    <h1 class="uppercase mb0">in the news</h1>
                </div>
            </div>
        </div>
    </section>
    <section id="posts-list" class="posts bg-secondary">
        <div class="container">
            <template v-for="(postsChunk, chunkIdx) in postsChunks" :key="chunkIdx">
                <div class="row mb32">
                    <div v-for="(post) in postsChunk" :key="`${postsChunks}_${post.id}`" class="col-sm-4 post-snippet">
                        <a :href="getLinkHref(post)" :target="getTarget(post.is_link)">
                            <img v-if="post.coverurl" class="post-image-thumb" alt="Post Image" :src="post.coverurl" />
                            <img v-else class="post-image-thumb" alt="Post Image" src="@/index/assets/img/post-bg.jpg" />
                        </a>
                        <div class="inner">
                            <a :href="getLinkHref(post)" :target="getTarget(post.is_link)">
                                <h5 class="mb0">{{ post.title }}</h5>
                            </a>
                            <hr />
                            <a :target="getTarget(post.is_link)" class="btn btn-sm" :href="getLinkHref(post)">Read More</a>
                        </div>
                    </div>
                </div>
                <div class="clearfix"></div>
            </template>
        </div>
    </section>
</template>
<style lang="scss">
#posts-list {
    img {
        width: 100%;
   height: 240px;
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
    }
}
</style>
